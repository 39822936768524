<template>
    <div class="tables">
        <table border="0" cellpadding="0" cellspacing="0" width="100%">
            <tr>
                <th align="center" class="tableTh" v-if="checkbox" width="50px">
                    <el-checkbox v-model="CheckboxAll" @change="AllchangeCheckbox"></el-checkbox>    
                </th>
                <th align="center" class="tableTh" v-if="indexType" width="100px">序号</th>
                <th align="left" class="tableTh" v-for="header in headers" :key="header.key">{{ header.label }}</th>
            </tr>
            <tr class="tableTr" v-for="(row, index) in tableData" :key="index" data-aos="zoom-in" :data-aos-delay="index*100">
                <td align="center" class="tableTd" v-if="checkbox">
                    <el-checkbox v-model="row.checkboxs" @change="changeCheckbox($event,row)"></el-checkbox>
                </td>
                <td align="center" class="tableTd" v-if="indexType">
                    {{index+1}}
                </td>
                <td align="left" class="tableTd" v-for="header in headers" :key="header.key">
                    {{ formatCellData(row, header) }}
                </td>
            </tr> 
        </table>
        <my-pagination style="margin-top: 20px;" v-if="pagination" :total="total" :pageSizi="initParam.pageSize"
            :PageNum.sync="initParam.page"></my-pagination>
    </div>
</template>
<script>
export default {
    name: 'MyTable',
    props: {
        headers: {//渲染头部
            type: Array,
            required: true,
            validator: function (value) {
                return value.every(header => header.key && header.label);
            }
        },
        requestApi:{//接口
            type:Function,  
            default:()=>{}
        },
        initParam:{//请求参数
            type:Object,
            default:{}
        },
        pagination:{//是否显示分页
            type: Boolean,  
            default:true
        },
        checkbox:{//是否显示勾选
            type: Boolean,  
            default:false
        },
        indexType:{//是否显示索引
            type: Boolean,  
            default:false
        }
    },
    watch:{
        initParam:{
            handler(data){
                this.callPropFunction()
            },
            deep: true
        },
        tableData:{//勾选判断全选框的状态
            handler(array){
                const allCompleted = array.every(item => item.checkboxs);
                if(allCompleted){
                    this.CheckboxAll = true
                }else{
                    this.CheckboxAll = false
                }
            },
            immediate: true,
            deep: true
        }
    },
    data() {
        return {
           tableData:[],//当前数据
           total:0,
           checkboxData:[],//勾选的数据
           CheckboxAll:false,//是否全选了
        }
    },
    methods: {
        //全选
        AllchangeCheckbox(data){
            let arr = [...this.tableData]
            if(data){//全选
                arr.forEach(item=>{item.checkboxs = true})
                this.checkboxData = arr
            }else{//全取消
                arr.forEach(item=>{item.checkboxs = false})
                this.checkboxData = []
            }
            this.$emit('checkboxFun', this.checkboxData); 
        },
        //单选
        changeCheckbox(event,obj){
            let arr = [...this.checkboxData]
            if(event){//true 勾选 判断在不在数组
                const Booleans = arr.some(item => JSON.stringify(item) === JSON.stringify(obj))
                if(!Booleans){//false 不在数组
                    this.checkboxData.push(obj)
                }
            }else{//取消 到数组删除
                const findIndex = arr.findIndex(item => JSON.stringify(item) === JSON.stringify(obj))
                this.checkboxData.splice(findIndex,1)
                console.log('全校',findIndex,this.tableData)

            }
            this.$emit('checkboxFun', this.checkboxData); 
        },
        formatCellData(row, header) {
            return row[header.key]
        },
        async callPropFunction() {
            const reslut = await this.requestApi({...this.initParam})
            let arr = reslut.data.Data.Data || []
            this.total = reslut.data.Data.Num
            //需要勾选
            if(this.checkbox){arr.forEach(item => {item.checkboxs = false});}
            this.tableData = arr
        } 
    },
    
}
</script> 
<style lang="scss" sp>
table{
    width: 100%;
}
.tableTh{
    color: #03aff6;
    padding-bottom: 10px;
}
.tableTd{
    padding: 15px 0;

}
// .tableTd:not(:last-child){
//     border-right:.5px solid rgba(#183c7c,.5);
// }
// .tableTr:nth-child(odd){
//     background: rgba(#183c7c,.2);
// }
.tableTr:nth-child(even){
    background: linear-gradient(to right,transparent,rgba(#183c7c,.2),transparent);
}
.tableTr:hover{
    background: linear-gradient(to right,transparent,rgba(#183c7c,.5),transparent);
    cursor: pointer;
}
</style>